<template>
  <div class="homePage mr-n2" ref="pageSizeMobile">
    <!-- On Dialog -->
    <v-dialog v-model="onDialog" max-width="350" class="onDialog">
      <v-card class="onCard">
        <v-card-title class="headline">
          Riasztó bekapcsolása
        </v-card-title>
        <v-card-text>
          Biztosan be szeretné kapcsolni a riasztót?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="setSmsInProgress">
            Igen
          </v-btn>
          <v-btn color="cyan darken-2" text @click="onDialog = false">
            Nem
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <!-- Off Dialog -->
        <v-dialog v-model="offDialog" max-width="350" class="offDialog">
      <v-card class="offCard">
        <v-card-title class="headline">
          Riasztó kikapcsolása
        </v-card-title>
        <v-card-text>
          Biztosan ki szeretné kapcsolni a riasztót?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="setSmsInProgress">
            Igen
          </v-btn>
          <v-btn color="cyan darken-2" text @click="offDialog = false">
            Nem
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-snackbar :absolute="isMobile" v-model="$store.state.snackbarTrigger" :timeout="3000" >
      {{ $store.state.snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="$store.commit('setSnackbarTrigger', false)"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Mobile -->
    <v-row
      align="center"
      justify="space-around"
      class="mt-12"
      v-if="isMobile && $store.state.currentPage === 'home'"
    >
      <v-btn
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="changePage('data')"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue"> mdi-account-circle </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>Adatok</h4>
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="changePage('events1')"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue"> mdi-clock </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>24 óra</h4>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <v-row
      align="center"
      justify="space-around"
      class="mt-6"
      v-if="isMobile && $store.state.currentPage === 'home'"
    >
      <v-btn
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="changePage('events30')"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue"> mdi-calendar </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>30 nap</h4>
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="changePage('email')"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue"> mdi-mail </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>E-mail</h4>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <v-row
      align="center"
      justify="space-around"
      class="mt-6"
      v-if="isMobile && $store.state.currentPage === 'home'"
    >
      <v-btn
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="changePage('receipts')"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue"> mdi-receipt-text </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>Számlák</h4>
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        :disabled=!this.$store.state.user.SMSNUM
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="()=>{if(this.$store.state.alarm) {offDialog = true} else if(!this.$store.state.alarm){onDialog = true}}"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue" v-if="!$store.state.alarm"> mdi-lock-open </v-icon>
            <v-icon size="60" color="blue" v-if="$store.state.alarm"> mdi-lock </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4 v-if="!$store.state.alarm">Bekapcsolás</h4>
            <h4 v-if="$store.state.alarm">Kikapcsolás</h4>
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        v-if="this.$store.state.user.isAdmin && !isMobile"
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click="changePage('admin')"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue">mdi-shield-account</v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>Admin</h4>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <v-row
      align="center"
      justify="space-around"
      class="mt-6 mb-n6"
      v-if="isMobile && $store.state.currentPage === 'home'"
    >
      <v-btn
        outlined
        color="grey"
        height="130"
        width="130"
        rounded
        @click.prevent="logout"
      >
        <v-row align="center" justify="center" class="d-flex">
          <v-col cols="12">
            <v-icon size="60" color="blue"> mdi-logout </v-icon>
          </v-col>
          <v-col cols="12" class="mt-n4 grey--text text--darken-4">
            <h4>Kilépés</h4>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>

    <!-- Desktop -->
    <v-row class="d-flex my-1" align="center" justify="center" v-if="!isMobile">
      <v-col class="d-flex align-center justify-center mt-12" ref="pageSize">
        <!-- User Data -->
        <v-card
        
          color="grey lighten-2"
          elevation="6"
          rounded
          class="px-8 mt-n5 rounded-xl"
          v-if="
            $store.state.currentPage === 'data' ||
            $store.state.currentPage === 'home'
          "
        >
        
          <v-row class="mt-2 mb-2" align="center" justify="center">
            <v-col cols="11" class="d-flex align-center justify-center">
              <v-card
             
                color="grey lighten-3"
                elevation="7"
                class="mr-2 rounded-xl"
              >
                <h4 class="mx-4 my-2 text-center">Felhasználói adatok</h4>
              </v-card>
            </v-col>
            <!-- Left block -->
            <v-col class="align-center justify-start" cols="4">
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Felhasználónév:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Név:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Szerződött név:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">E-mail:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Mobilszám:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Azonosító:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Cég:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Vonalszám:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Objektum:</h4>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <!-- Right block -->
            <v-col class="align-center justify-start" cols="6">
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.USERNAME"
                  >
                    {{ $store.state.user.USERNAME }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.NEV"
                  >
                    {{ $store.state.user.NEV }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.SZERZ_NEV"
                  >
                    {{ $store.state.user.SZERZ_NEV }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.EMAIL"
                  >
                    {{ $store.state.user.EMAIL }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.MOBILSZAM"
                  >
                    {{ $store.state.user.MOBILSZAM }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4 class="mx-2 my-2 text-center" v-if="$store.state.user">
                    {{ $store.state.user.ACCOUNT }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.CEG"
                  >
                    {{ $store.state.user.CEG }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.LINE"
                  >
                    {{ $store.state.user.LINE }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h4
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.OBJEKTUM"
                  >
                    {{ $store.state.user.OBJEKTUM }}
                  </h4>
                  <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <!-- Events -->
        
        <Events
          v-if="$store.state.currentPage == 'events1'"
          eventType="events1" 
        />

        <Events
          v-if="$store.state.currentPage == 'events30'"
          eventType="events30"
        />
       
        <!-- Receipts -->
        <Receipts v-if="$store.state.currentPage == 'receipts'" />
        
        <!-- Admin -->
        <Admin v-if="$store.state.currentPage == 'admin'" />
        
        <!-- E-mail -->
        <v-card
          color="grey lighten-2"
          min-width="400"
          v-if="$store.state.currentPage == 'email'"
          class="rounded-xl mt-n5"
        >
          <v-row class="d-flex" align="center" justify="center">
            <v-col class="d-flex align-center justify-start" cols="10">
              <h3 class="mx-4 mt-6 text-center">Lépjen velünk kapcsolatba</h3>
            </v-col>
            <v-col
              class="d-flex mt-6 align-center justify-start mr-8 ml-n6"
              cols="1"
            >
              <v-icon size="38" color="blue">mdi-mail</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-n2 mb-n12" align="center" justify="center">
            <v-col class="d-flex align-center justify-center mx-6">
              <v-text-field
                v-model="name"
                solo
                rounded
                label="Név"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-n6 mb-n6" align="center" justify="center">
            <v-col class="d-flex align-center justify-center mx-6">
              <v-text-field
                v-model="email"
                solo
                rounded
                label="E-mail"
              >
                <template v-slot:prepend-inner>
                  <h3 class="ml-n2 mr-2">@</h3>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex" align="center" justify="center">
            <v-col class="align-center justify-center mx-6">
              <v-text-field
                v-model="subject"
                solo
                rounded
                label="Tárgy"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-n11" align="center" justify="center">
            <v-col class="align-center justify-center mx-6">
              <v-textarea
                auto-grow
                v-model="message"
                solo
                rounded
                label="Üzenet"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-n10" align="center" justify="end">
            <v-col class="d-flex align-center justify-end mx-6">
              <v-btn rounded color="blue" class="mb-2" @click="sendMessage">
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Mobile -->
    <v-row
      class="mt-n10 mx-1"
      justify="center"
      v-if="isMobile"
    >
      <v-col class="d-flex align-center justify-center mt-12">
        <!-- User Data -->
        <v-card
          color="grey lighten-2"
          elevation="6"
          rounded
          class="py-5 rounded-xl"
          v-if="$store.state.currentPage === 'data'"
        >
          <v-row align="center" justify="center">
            <v-col cols="1" class="d-flex align-center justify-start">
              <v-btn
                rounded
                small
                @click.prevent="$store.state.currentPage = 'home'"
              >
                <v-icon size="20"> mdi-arrow-left-thick </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10" class="d-flex align-center justify-center">
              <v-card
                color="grey lighten-3"
                elevation="7"
                class="mr-2 rounded-xl"
              >
                <h5 class="mx-4 my-2 text-center">
                  Felhasználói adatok
                </h5>
              </v-card>
            </v-col>
            <!-- Left block -->
            <v-col class="align-center justify-start ml-2 mr-n12">
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Felhasználónév:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Név:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Szerződött név:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">E-mail:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Mobilszám:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h4 class="mx-4 my-2 text-center">Azonosító:</h4>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Cég:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Vonalszám:</h5>
                  </v-card>
                </v-col>
              </v-row>
              <v-spacer class="my-1"></v-spacer>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <v-card
                    color="grey lighten-3"
                    elevation="7"
                    class="mr-2 rounded-xl"
                  >
                    <h5 class="mx-4 my-2 text-center">Objektum:</h5>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <!-- Right block -->
            <v-col class="align-center justify-start ml-n12">
              <v-row align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.USERNAME"
                  >
                    {{ $store.state.user.USERNAME }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.NEV"
                  >
                    {{ $store.state.user.NEV }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.SZERZ_NEV"
                  >
                    {{ $store.state.user.SZERZ_NEV }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.EMAIL"
                  >
                    {{ $store.state.user.EMAIL }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.MOBILSZAM"
                  >
                    {{ $store.state.user.MOBILSZAM }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5 class="mx-2 my-2 text-center" v-if="$store.state.user">
                    {{ $store.state.user.ACCOUNT }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.CEG"
                  >
                    {{ $store.state.user.CEG }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.LINE"
                  >
                    {{ $store.state.user.LINE }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="d-flex" align="center" justify="start">
                <v-col class="d-flex align-center justify-start">
                  <h5
                    class="mx-2 my-2 text-center"
                    v-if="$store.state.user.OBJEKTUM"
                  >
                    {{ $store.state.user.OBJEKTUM }}
                  </h5>
                  <h5 class="mx-2 my-2 text-center" v-else>Nincs adat</h5>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <!-- Events -->
        <Events
          v-if="$store.state.currentPage == 'events1'"
          eventType="events1"
        />
        <Events
          
          v-if="$store.state.currentPage == 'events30'"
          eventType="events30"
        />
        <!-- Receipts -->
        <Receipts v-if="$store.state.currentPage == 'receipts'" />
        <!-- Admin -->
        <Admin v-if="$store.state.currentPage == 'admin'" />
        <!-- E-mail -->
        <v-card
          color="grey lighten-2"
          min-width="360"
          v-if="$store.state.currentPage == 'email'"
          class="rounded-xl mx-2"
        >
          <v-row class="d-flex" align="center" justify="center">
            <v-col cols="1" class="d-flex align-center justify-start">
              <v-btn
                rounded
                small
                @click.prevent="$store.state.currentPage = 'home'"
              >
                <v-icon size="20"> mdi-arrow-left-thick </v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-end" cols="9">
              <h4 class="mx-4 mt-6 text-center blue--text">
                Lépjen velünk kapcsolatba
              </h4>
            </v-col>
            <v-col
              class="d-flex mt-6 align-center justify-start mr-8 ml-n6"
              cols="1"
            >
              <v-icon size="25" color="blue">mdi-mail</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-n2 mb-n12" align="center" justify="center">
            <v-col class="d-flex align-center justify-center mx-6">
              <v-text-field
                v-model="name"
                solo
                rounded
                label="Név"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-n6 mb-n6" align="center" justify="center">
            <v-col class="d-flex align-center justify-center mx-6">
              <v-text-field
                v-model="email"
                @focus="changeEmailIcon"
                solo
                rounded
                label="E-mail"
              >
                <template v-slot:prepend-inner>
                  <h3 class="ml-n2 mr-2">@</h3>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex" align="center" justify="center">
            <v-col class="align-center justify-center mx-6">
              <v-text-field
                v-model="subject"
                solo
                rounded
                label="Tárgy"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-n11" align="center" justify="center">
            <v-col class="align-center justify-center mx-6">
              <v-textarea
                auto-grow
                v-model="message"
                solo
                rounded
                label="Üzenet"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-n10" align="center" justify="end">
            <v-col class="d-flex align-center justify-end mx-6">
              <v-btn
                rounded
                color="blue"
                class="mb-2"
                @click="sendMessage"
              >
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import itemCard from '@/components/home/ItemCard'
import Vuex from "vuex";
import { isMobile } from "mobile-device-detect";
import db from "@/firebase/init.js";
import { collection, addDoc, getDoc, doc } from "firebase/firestore";
import Events from "../components/Events";
import Receipts from "../components/Receipts.vue";
import { StatusIndicator } from "vue-status-indicator";
import Admin from "../components/Admin.vue";

export default {
  name: "HomePage",
  components: {
    StatusIndicator,
    Events,
    Receipts,
    Admin
  },

  data() {
    return {
      isMobile: isMobile,
      currentPage: "data",
      pages: ["data", "24hours", "30days", "email"],
      name: "",
      onDialog: false,
      offDialog: false,
      email: "",
      interval: null,
      smsTimer: null,
      alarmTimer: null,
      alarmTimer2: null,
      subject: "",
      message: "",
      events: [],
      isAdmin: false,
      adminUsers: [],
      editingUser: null,
    };
  },
  mounted(){
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if(!this.isMobile){
      this.$store.state.desktopScrollHeight = (window.innerHeight-278)
    }
    else {
      this.$store.state.mobileScrollHeight = (window.innerHeight-275)
    }
    
  },
  
  created() {
    this.checkAdminStatus();
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    
    async checkAdminStatus() {
        try {
          const adminDocRef = collection(db, "admin");
          const usersDocRef = doc(adminDocRef, "users");
          const docSnap = await getDoc(usersDocRef);
          
          if (docSnap.exists()) {
            console.log("USERS EXIST");
            const adminUsernames = docSnap.data().usernames || [];
            this.adminUsers = adminUsernames;
            console.log("ADMIN USERS:", adminUsernames);
            this.$store.state.user.isAdmin = adminUsernames.includes(this.$store.state.user.USERNAME);
          }
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      },
    onResize() {
      if(!this.isMobile){
      this.$store.state.desktopScrollHeight = (window.innerHeight-278)
    }
    else {
      this.$store.state.mobileScrollHeight = (window.innerHeight-275)
    }
    },
    logout() {
      this.$store.commit("setUser", null);
      if (this.$route.name != "Login") {
        this.$router.push({ name: "Login" });
      }
      this.$store.state.currentPage = "home";
    },
    setSmsInProgress(){
      this.offDialog = false
      this.onDialog = false
      if(!this.$store.state.smsInProgress){
          this.$store.state.smsInProgress = true
        }
        else {
          this.$store.commit("setSnackbarTrigger", false);
          setTimeout(
            () =>
              this.delayedNotification(
                "A be/ki riasztás már folyamatban van!",
                3000,
                true
              ),
            100
          );
        } 
    },
    delayedNotification(message,timeout,bool) {
        this.$store.commit('setSnackbarMessage', message)
        this.$store.commit('setSnackbarTimeout', timeout)
        this.$store.commit('setSnackbarTrigger', bool)
    },
    changePage(page) {
      this.$store.state.currentPage = page;
      if(!this.isMobile){
      this.$store.state.desktopScrollHeight = (window.innerHeight-278)
    }
    else {
      this.$store.state.mobileScrollHeight = (window.innerHeight-275)
    }
    },

    async sendMessage() {
      if (this.subject && this.message) {
        if (!this.name) {
          this.name = this.$store.state.user.NEV;
        }
        if (!this.email) {
          this.email = this.$store.state.user.EMAIL;
        }
        try {
          const docRef = await addDoc(collection(db, "mail"), {
            to: "rimi@rimi.hu",
            message: {
              subject: "Ügyfélszolgálati üzenet a rimi.hu oldalról",
              text:
                "Ügyfél neve: " +
                this.name +
                "\n" +
                "Ügyfél e-mail címe: " +
                this.email +
                "\n" +
                "Tárgy: " +
                this.subject +
                "\n" +
                "Üzenet: " +
                this.message,
            },
          });
          //Snackbar here
          this.$store.commit("setSnackbarMessage", "Sikeres üzenetküldés!");
          this.$store.commit("setSnackbarTimeout", 3000);
          this.$store.commit("setSnackbarTrigger", true);
          this.name = "";
          this.email = "";
          this.subject = "";
          this.message = "";
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    },

  },

  beforeDestroy(){
    clearInterval(this.alarmTimer);
    clearInterval(this.interval)
    clearInterval(this.alarmTimer2)
    clearInterval(this.smsTimer)
  
  },

};
</script>
<style></style>
