<template>
  <v-container fluid>
    <!-- Snackbar -->
    <v-snackbar v-model="$store.state.snackbarTrigger" :timeout="3000">
      {{ $store.state.snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="cyan darken-2" text v-bind="attrs" @click="$store.commit('setSnackbarTrigger', false)">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <v-btn rounded class="ml-n2 mt-n12" small @click.prevent="$store.state.currentPage = 'home'" v-if="isMobile">
      <v-icon size="20"> mdi-arrow-left-thick </v-icon>
    </v-btn>

    <!-- Desktop -->
    <v-row class="d-flex mb-2 mt-n8" align="center" justify="center" v-if="!isMobile">
      <v-btn class="d-flex rounded-xl" width="98%">
        <v-row class="d-flex" align="center" justify="center">
          <v-col cols="1" class="ml-n5">
            <h4>Fiókszám</h4>
          </v-col>
          <v-col cols="1">
            <h4>Vonalszám</h4>
          </v-col>
          <v-col cols="2">
            <h4>Idő</h4>
          </v-col>
          <v-col cols="7">
            <h4>Leírás</h4>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <!-- Mobile -->
    <v-row class="d-flex mb-2" align="center" justify="center" v-if="isMobile">
      <v-btn class="d-flex rounded-xl" width="100%">
        <v-row class="d-flex" align="center" justify="center">
          <v-col cols="3" class="align-center justify-start">
            <h5>Idő</h5>
          </v-col>
          <v-col cols="8">
            <h5>Leírás</h5>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <div v-if="sortedEvents != 'EMPTY'" v-bind:class="{ 'mt-5 mx-n5': isMobile, 'mt-5': !isMobile }">
      <v-row class="d-flex mx-n6" align="center" justify="center">
        <v-virtual-scroll v-if="!isMobile" :items="sortedEvents" :item-height=60 bench="50"
          :height="$store.state.desktopScrollHeight.toString()">
          <template v-slot:default="{ item }">
            <v-list-item :key="item.ACCOUNT + item.LINE + item.IDO">
              <EventsCard class="mb-4" :userEvent="item" />
            </v-list-item>
          </template>
        </v-virtual-scroll>
        <v-virtual-scroll v-if="isMobile" :items="sortedEvents" :item-height=100 :height="$store.state.mobileScrollHeight"
          bench="15">
          <template v-slot:default="{ item }">
            <v-list-item :key="item.ACCOUNT + item.LINE + item.IDO">
              <EventsCard class="mb-4" :userEvent="item" />
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-row>
    </div>
    <div v-else>
      <v-row align="center" justify="center" class="mt-4">
        <h4>Nincs megjeleníthető esemény</h4>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { isMobile } from "mobile-device-detect";
import EventsCard from "./EventsCard";

export default {
  name: "Events",
  components: {
    EventsCard,
  },
  props: ["eventType"],
  data() {
    return {
      isMobile: isMobile,
      events: [],
      date: null,
      eventTimer: null,
      getEvents1Timer: null,
      getEvents30Timer: null,

    };
  },
  created() {
    this.setEventTimer();

    // if (this.eventType == "events1") {
    //   this.getEvents1("default");
    //   // var that = this;
    //   // this.getEvents1Timer = setInterval(function () {
    //   //   that.getEvents1("timer");
    //   // }, 30000);
    // }
    // if (this.eventType == "events30") {
    //   this.getEvents30("default");
    //   // var that = this;
    //   // this.getEvents30Timer = setInterval(function () {
    //   //   that.getEvents30("timer");
    //   // }, 30000);
    // }
  },

  computed: {
    sortedEvents: {
      get: function () {
        if (this.events != "EMPTY") {
          return this.events.sort((a, b) => {
            if (a.IDO < b.IDO) {
              return 1;
            }
            if (a.IDO > b.IDO) {
              return -1;
            }
            return 0;
          });
        } else {
          return "EMPTY";
        }
      },
    },
  },
  methods: {
    setEventTimer() {
      clearInterval(this.eventTimer);
      if (this.eventType === "events1") {
        this.getEvents1("default");
        this.eventTimer = setInterval(() => this.getEvents1("timer"), 30000);
      } else if (this.eventType === "events30") {
        this.getEvents30("default");
        this.eventTimer = setInterval(() => this.getEvents30("timer"), 30000);
      }
    },

    getEvents1(type) {
      clearInterval(this.getEvents30Timer);
      if (isMobile) {
        this.height = 80
      }
      else {
        this.height = 60
      }
      if (type == "default" && !this.$store.state.smsInProgress) {
        this.$store.state.wsOverlay = true;
      }

      // Get ONAPLO events for the last 24 hours
      axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
        params: {
          uid: this.$store.state.user.token,
          eventType: 'getEventsOnaplo',
          account: this.$store.state.user.ACCOUNT,
          line: this.$store.state.user.LINE
        },
        withCredentials: true
      })
        .then(response => {
          if (typeof response.data === "object") {
            this.events = response.data.filter(event =>
                !(event.LEIRAS && event.LEIRAS.includes("Jelismétl")));


          } else {
            this.events = "EMPTY";
          }
          this.$store.state.wsOverlay = false;
        })
        .catch((error) => {
          this.$store.state.wsOverlay = false;
          console.log(error);
          //Snackbar here
          this.$store.commit("setSnackbarTrigger", false);
          setTimeout(
            () =>
              this.delayedNotification(
                "Adatbázis kapcoslat sikertelen events:(",
                3000,
                true
              ),
            100
          );
        });
    },
    getEvents30(type) {
      if (isMobile) {
        this.height = 80
      }
      else {
        this.height = 60
      }
      clearInterval(this.getEvents1Timer);
      this.date = this.getDateXDaysAgo(30);
      if (type == "default") {
        this.$store.state.wsOverlay = true;
      }

      // Get all events for account
      axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
        params: {
          uid: this.$store.state.user.TOKEN,
          eventType: 'getEvents',
          account: this.$store.state.user.ACCOUNT,
          line: this.$store.state.user.LINE,
          date: this.date
        },
        withCredentials: true
      })
        .then(response => {
          if (typeof response.data === "object") {
            this.events = response.data.filter(event =>
                !(event.LEIRAS && event.LEIRAS.includes("Jelismétl")));


          } else {
            this.events = "EMPTY";
          }
          this.$store.state.wsOverlay = false;
        })
        .catch((error) => {
          this.$store.state.wsOverlay = false;
          console.log(error);
          //Snackbar here
          this.$store.commit("setSnackbarTrigger", false);
          setTimeout(
            () =>
              this.delayedNotification(
                "Adatbázis kapcoslat sikertelen events:(",
                3000,
                true
              ),
            100
          );
        });
    },
    delayedNotification(message, timeout, bool) {
      this.$store.commit("setSnackbarMessage", message);
      this.$store.commit("setSnackbarTimeout", timeout);
      this.$store.commit("setSnackbarTrigger", bool);
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date.getTime());
      daysAgo.setDate(date.getDate() - numOfDays);
      let dateWithoutHours =
        daysAgo.getFullYear().toString() +
        "-" +
        (daysAgo.getMonth() + 1).toString() +
        "-" +
        daysAgo.getDate().toString();
      return dateWithoutHours;
    },
  },
  watch: {
    "$store.state.triggerGetEvents"(val) {
      // this.getEvents1("auto")
      // this.getEvents30("auto")
      this.setEventTimer();
    },
    "$store.state.currentPage": function (val) {
      this.eventType = val;
      this.setEventTimer();
    },
  },
  beforeDestroy() {
    clearInterval(this.eventTimer);
  },
};
</script>

<style></style>
