<template>
  <v-card color="grey lighten-2" elevation="6" rounded class="px-8 mt-n5 rounded-xl">
    <v-row class="mt-2 mb-2" align="center" justify="center">
      <v-col cols="11" class="d-flex align-center justify-center">
        <v-card color="grey lighten-3" elevation="7" class="mr-2 rounded-xl">
          <h4 class="mx-4 my-2 text-center">Admin felület</h4>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12" class="mt-n5 mx-2">
        <v-row class="d-flex align-center">
          <v-col cols="8" class="d-flex align-center">
            <v-text-field
            class="d-flex align-center justify-center"
            background-color="white"
            height="40"
              v-model="newAdminUser"
              label="Új admin felhasználó"
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <v-btn small color="blue" height="35" @click="addAdminUser" rounded class="ml-n4 mt-n4">
              <v-icon left color="white">mdi-plus</v-icon>
              <span class="white--text">Hozzáadás</span>
            </v-btn>
          </v-col>
        </v-row>
        
        <v-list color="transparent">
          <v-list-item 
            v-for="(user, index) in adminUsers" 
            :key="index"
            class="mb-2 rounded d-flex align-center"
            :class="{'grey lighten-3': index % 2 === 0, 'grey lighten-4': index % 2 !== 0}"
            min-height="40"
          >
            <v-list-item-content class="py-2">
              <v-list-item-title class="d-flex align-center">{{ user }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
              <v-btn icon small @click="removeAdminUser(user)">
                <v-icon color="red" small>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import db from "@/firebase/init.js";

export default {
  name: "AdminPanel",
  data() {
    return {
      adminUsers: [],
      newAdminUser: ""
    };
  },
  created() {
    this.fetchAdminUsers();
  },
  methods: {
    async fetchAdminUsers() {
      try {
        const adminDocRef = doc(db, "admin", "users");
        const docSnap = await getDoc(adminDocRef);
        
        if (docSnap.exists()) {
          this.adminUsers = docSnap.data().usernames || [];
        }
      } catch (error) {
        console.error("Error fetching admin users:", error);
      }
    },
    
    async addAdminUser() {
      if (!this.newAdminUser.trim()) return;
      
      try {
        const adminDocRef = doc(db, "admin", "users");
        await updateDoc(adminDocRef, {
          usernames: arrayUnion(this.newAdminUser)
        });
        
        this.adminUsers.push(this.newAdminUser);
        this.newAdminUser = "";
      } catch (error) {
        console.error("Error adding admin user:", error);
      }
    },
    
    async removeAdminUser(username) {
      try {
        const adminDocRef = doc(db, "admin", "users");
        await updateDoc(adminDocRef, {
          usernames: arrayRemove(username)
        });
        
        this.adminUsers = this.adminUsers.filter(user => user !== username);
      } catch (error) {
        console.error("Error removing admin user:", error);
      }
    }
  }
};
</script>
